// # ================================================================ #
// #    Author: Shohrab Hossain                                       #
// # ================================================================ #
// #                                                                  #
// #    Event Service Module:                                         #
// #        this module holds the functions to make api calls         #
// #                                                                  #
// # ================================================================ #


// importing libraries
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import utils from '@/utils.js'
import FetchUtils from '@/services/FetchUtils.js'
import Validator from '@/services/Validator.js'



// defining base url to make api calls
const baseURL = store.state.baseURL;

// defining ML model api
const mlServerURL = store.state.mlServerURL;

// // defining base url to make api calls
// const openai_api = store.state.openai_api;


// defining AXIOS api 
const connect_ml_server = axios.create({
  baseURL: mlServerURL,
  withCredentials: false, // the default is false
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 40000
})




// defining AXIOS upload-api
// const connect_openai = axios.create({
//   baseURL: openai_api,
//   withCredentials: false, // the default is false
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'multipart/form-data',
//   },
//   timeout: 40000
// })




// -------------------------------------------------------------|
//                                                              |
//    Information Extraction API                                |
//                                                              |
// -------------------------------------------------------------|
async function extractInfo (payload){
  try {
    const res = await connect_ml_server.post('/api/extract-info', payload);
    
    if (res.data) store.dispatch('HomeViewStore/updateInputData', res.data);

    return res.data;

  } catch (error) {
    axiosErrorHandler(error);
    throw error;
  }
}





// -------------------------------------------------------------|
//                                                              |
//    Cost-Prediction api                                       |
//                                                              |
// -------------------------------------------------------------|
async function getCostPrediction (payload){
  try {
    const res = await connect_ml_server.post('/api/prediction', payload);
    
    // server successfully processed the request and send the reply back
    if (res.data) {
      store.dispatch('HomeViewStore/updatePrediction', res.data.prediction);
      store.dispatch('HomeViewStore/updateIsAvailableCostPrediction', true);
      
      // moving to result-view page
      router.push({name: 'CostPlan'})
    }

    return res.data;

  } catch (error) {
    axiosErrorHandler(error);
    throw error;
  }
}




// -------------------------------------------------------------|
//                                                              |
//    Cost-Report Generation API                                |
//                                                              |
// -------------------------------------------------------------|
async function generateCostReport (payload, where_to_push){
  // this counter will keep track of the number of api being called
  var counter_openai_generateResponse = 0

  // updating the store
  store.dispatch('HomeViewStore/updateCostPlanStatus', 'processing');
  store.dispatch('HomeViewStore/updateIsAvailableCostPlan', false);
  

  // calling the api 3times after 2sec, 2times in each loops 
  for (let i = 0; i < 3; i++)
    for (let j = 0; j < 3; j++)
      setTimeout(initiate_api_call, i * 5000)
  
  async function initiate_api_call(){
    try {
      // updating the counter on api hit
      counter_openai_generateResponse++;
      
      // calling the api
      const res = await connect_ml_server.post('/api/generate-response', payload);
      
      // decreasing the couinter as api call ended
      counter_openai_generateResponse--;

      // updating store after validating the response
      if (res.data && Validator.validate_response(res.data)) {
        // senitizing the response
        let senitized_response = Validator.senitize_response(res.data)

        store.dispatch('HomeViewStore/updateResponseArchive', senitized_response);
        // store.dispatch('HomeViewStore/updateResponse', res.data);
        
        // if response is not avaiable, only then updating the response
        let response_ = store.state.HomeViewStore.response
        if(Object.keys(response_).length === 0){
          store.dispatch('HomeViewStore/updateResponse', senitized_response);
        }

        // checking all the api call done
        if(counter_openai_generateResponse == 0) {
          store.dispatch('HomeViewStore/updateCostPlanStatus', 'success');
        }

        store.dispatch('HomeViewStore/updateIsAvailableCostPlan', true);
      }

      // after each response generation storing the result locally
      FetchUtils.storeHistoryLocally(where_to_push);

      // passing the response
      return res.data;

    } catch (error) {
      // decreasing the couinter as api call ended
      counter_openai_generateResponse--;

      // accessing the api status
      let status = store.state.HomeViewStore.updateCostPlanStatus
      
      // checking all the api call done
      if(counter_openai_generateResponse == 0 && status != 'success'){
        store.dispatch('HomeViewStore/updateCostPlanStatus', 'failed');
        
        // axiosErrorHandler(error);
        // throw error;
      }
    }
  }
}





// -------------------------------------------------------------|
//                                                              |
//    Exporting the module                                      |
//                                                              |
// -------------------------------------------------------------|

export default {
  extractInfo,
  getCostPrediction,
  generateCostReport,
}





// -------------------------------------------------------------|
//                                                              |
//    Custom functions definition area                          |
//                                                              |
// -------------------------------------------------------------|


// this function handles all the AXIOS error and flashes the error as notification modal
function axiosErrorHandler (error){
  // defining the error message
  let errorMessage = {catagory: 'error', title: '', message: ''};

  // adding custom message for more detailed notification
  if (error.response) {        // The request was made and the server responded with a status code that falls out of the range of 2xx
    errorMessage.title   = `Server replied with an Error Code : ${error.response.status}`;
    errorMessage.message = `Request send to the server and the server replied with an error. \n ${JSON.stringify(error.response.data)}`;
  } else if (error.request) {  // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance oF http.ClientRequest in node.js
    errorMessage.title   = `No response from the server`;
    errorMessage.message = `Request send to the server but the server did not sent any response. \n ${JSON.stringify(error.request)}`;
  } else {                     // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
    errorMessage.title   = `Connection Problem`;
    errorMessage.message = `Error occured while stablishing connection with the server. \n ${error.message}`;
  }

  // adding the error to notification store, from where the modal will be flashed
  store.dispatch('notificationStore/showNotification', errorMessage);
}


export const namespaced = true

export const state = {
  about_us: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consequat ligula sapien, pharetra porta odio mattis eget. Nullam ac faucibus velit. Vestibulum viverra eleifend tellus, quis molestie velit convallis et. Pellentesque sollicitudin ex quis mi dignissim lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis nunc vitae urna tempus, at lacinia metus tristique. Phasellus augue est, auctor et lectus vitae, venenatis scelerisque nunc. Cras a efficitur quam. Donec ac sem ultrices, volutpat ex ac, pellentesque ante. Quisque neque augue, dapibus ut tincidunt sit amet, blandit vel ante. Vestibulum vel mi nisl. Suspendisse sed diam id turpis lobortis ultrices. Sed semper pharetra ex.`,
  mission: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas consequat ligula sapien, pharetra porta odio mattis eget. Nullam ac faucibus velit. Vestibulum viverra eleifend tellus, quis molestie velit convallis et. Pellentesque sollicitudin ex quis mi dignissim lacinia. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mollis nunc vitae urna tempus, at lacinia metus tristique. Phasellus augue est, auctor et lectus vitae, venenatis scelerisque nunc. Cras a efficitur quam. Donec ac sem ultrices, volutpat ex ac, pellentesque ante. Quisque neque augue, dapibus ut tincidunt sit amet, blandit vel ante. Vestibulum vel mi nisl. Suspendisse sed diam id turpis lobortis ultrices. Sed semper pharetra ex.`,
  technology: `Nullam posuere suscipit mi et hendrerit. Aliquam leo neque, semper in lacus sodales, aliquam accumsan eros. Etiam sollicitudin viverra neque, luctus rutrum nulla tempus ac. Maecenas eu metus elementum, maximus enim a, elementum dolor. Cras tortor urna, commodo sit amet imperdiet ut, semper eu quam. Pellentesque et cursus odio, id facilisis dolor. Nullam interdum ex vel arcu laoreet vestibulum. Nam euismod mattis lorem quis luctus. Aliquam elementum risus eget sapien elementum, eu tristique tortor porttitor. Morbi pellentesque ante quis sem elementum lobortis. Nullam metus nisi, volutpat non urna id, sodales porttitor tortor.`,
  services: [
    {
      heading: `Lorem ipsum dolor`,
      content: `Cras id lobortis lectus, ac malesuada ante. Pellentesque mattis, diam in tempus faucibus, leo eros convallis neque, ac egestas odio nunc ac nisl. Donec ullamcorper augue quis nunc molestie, nec convallis orci congue. Proin eget ligula eleifend justo imperdiet mollis nec ac massa. Donec in sollicitudin massa. Morbi tortor velit, aliquam id imperdiet a, bibendum a lacus. Maecenas sagittis, lacus ut pretium congue, tortor leo commodo enim, vitae ultricies ante lorem vel ante. Ut gravida elementum egestas.`,
    },
    {
      heading: `Lorem ipsum dolor`,
      content: `Vivamus condimentum imperdiet purus at dapibus. Aliquam aliquet tincidunt nulla, in tincidunt ipsum porttitor eget. Aliquam in luctus odio. In rutrum scelerisque velit eget sollicitudin. Nunc a elit non felis consectetur elementum quis a nunc. Vestibulum at arcu id nunc pharetra ullamcorper porttitor nec diam. Nullam quis elit nec mauris vestibulum efficitur eget a ligula.`,
    },
    {
      heading: `Lorem ipsum dolor`,
      content: `Vivamus condimentum imperdiet purus at dapibus. Aliquam aliquet tincidunt nulla, in tincidunt ipsum porttitor eget. Aliquam in luctus odio. In rutrum scelerisque velit eget sollicitudin. Nunc a elit non felis consectetur elementum quis a nunc. Vestibulum at arcu id nunc pharetra ullamcorper porttitor nec diam. Nullam quis elit nec mauris vestibulum efficitur eget a ligula.`,
    },
  ],
  commitment_to_innovation: `Integer vitae elementum augue, eu accumsan elit. Vestibulum vehicula venenatis nulla at tincidunt. Nam fringilla eleifend tortor, nec fringilla neque faucibus quis. Nulla tempus arcu sit amet dolor tempor fringilla. Maecenas dapibus a urna in posuere. Etiam nec sollicitudin magna. Fusce ac bibendum mi. Vivamus porta, ipsum in posuere volutpat, nisi erat rhoncus purus, eget mollis nulla lorem sit amet enim. Suspendisse potenti. Pellentesque sed ligula eget nunc dictum malesuada non vitae mauris. Aenean dictum ullamcorper leo quis tempor. Vestibulum sed velit dignissim, ultrices ex id, volutpat sapien. In hac habitasse platea dictumst. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent ut sapien ac eros convallis hendrerit. Donec cursus est quis mauris varius aliquam.`,
  vision_for_the_future: `Integer vitae elementum augue, eu accumsan elit. Vestibulum vehicula venenatis nulla at tincidunt. Nam fringilla eleifend tortor, nec fringilla neque faucibus quis. Nulla tempus arcu sit amet dolor tempor fringilla. Maecenas dapibus a urna in posuere. Etiam nec sollicitudin magna. Fusce ac bibendum mi. Vivamus porta, ipsum in posuere volutpat, nisi erat rhoncus purus, eget mollis nulla lorem sit amet enim. Suspendisse potenti. Pellentesque sed ligula eget nunc dictum malesuada non vitae mauris. Aenean dictum ullamcorper leo quis tempor. Vestibulum sed velit dignissim, ultrices ex id, volutpat sapien. In hac habitasse platea dictumst. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent ut sapien ac eros convallis hendrerit. Donec cursus est quis mauris varius aliquam.`,
}

export const mutations = {
  // UPDATE_HISTORY(state, history) {
  //   // updating the whole state with new-data
  //   state.history = history;
  // },
}


export const actions = {
  // updateHistory({ commit }, history) {
  //   commit('UPDATE_HISTORY', history)
  // },
}

// =======================================================================|
//                                                                        |
//    Utility module : holds all the utility functions and variables      |
//                                                                        |
// =======================================================================|




// -------------------------------------------------------
//    this function flashes the notification modal       |
// -------------------------------------------------------
function showNotificationModal() {
  // defining the modal
  var notificationModal = new bootstrap.Modal(
                              document.getElementById('notificationModal'), 
                              { 
                                keyboard: false /* This enables notification modal */ 
                              }
                          )

  // flashing the modal
  notificationModal.show();
}


// -------------------------------------------------------
//    this function initiate the popovers                |
// -------------------------------------------------------
function initiateAllPopover(dynamicConfig) {
  const fixedConfig = {
                        trigger: dynamicConfig.trigger ? dynamicConfig.trigger : 'focus',  // 'focus' will close popover in next-click
                        sanitize: true,  // this will senitize the html input
                        container: 'body',  // this will maintain separate container, to isolate styling for the popover
                      }

  if (typeof dynamicConfig != 'object') dynamicConfig = {};

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, {
      ...fixedConfig,
      ...dynamicConfig
    })
  })
}



// -------------------------------------------------------
//    this function initiate the Tooltips                |
// -------------------------------------------------------
function initiateAllTooltips() {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
}




// -------------------------------------------------------
//    defining all base-url                              |
// -------------------------------------------------------
const baseURL = {
  main: `https://boq.ai`,
  dev: `https://dev.boq.ai`,
  local: `http://localhost:8080`,
}



// -------------------------------------------------------
//    defining all ml-api-url                              |
// -------------------------------------------------------
const mlServerURL = {
  aws: {
    test: `https://vvvc.execute-api.eu-west-2.amazonaws.com/testing/api/get-early-cost-prediction`,
    production: `https://mlserver.boq.ai`,
  }
}





// -------------------------------------------------------
//    exporting the utils                                |
// -------------------------------------------------------
export default {
  showNotificationModal,
  initiateAllPopover,
  initiateAllTooltips,

  baseURL: baseURL.main,

  mlServerURL: mlServerURL.aws.production,
}
<template>
  <div>
    <!-- Notification Modal gets called when Store receives a Commit for BaseNotificationModal component -->

    <!-- Modal -->
    <div class="modal fade" id="notificationModal" tabindex="-1" aria-labelledby="notificationModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="notificationModalLabel">
              <span v-if="this.catagory.toLowerCase() === 'error'"> <i class="fas fa-exclamation-triangle fa-fw"></i>
              </span>
              <span> {{ this.title }} </span>
            </h5>
            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
          </div>

          <div class="modal-body">
            <span> {{ this.message }} </span>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BaseNotificationModal',
  props: {
    /* notification: {
      catagory: {type: String, required: true, default: ''},
      title:    {type: String, required: true, default: ''},
      message:  {type: String, required: true, default: ''}
    } */
  },

  data() {
    return {
      nullNotification: { /* this data will be shown if notification prop has any data missing. */
        catagory: 'null',
        title: 'warning: Title is empty',
        message: 'warning: Message is empty',
      }
    }
  },

  methods: {
    getDataFromProps(propName) {
      if (this.notification[propName] !== undefined && this.notification[propName].length !== 0) {
        return this.notification[propName];
      } else {
        return this.nullNotification[propName];
      }
    }
  },

  computed: {
    ...mapState('notificationStore', ['notification']),

    catagory() { return this.getDataFromProps('catagory') },
    title() { return this.getDataFromProps('title') },
    message() { return this.getDataFromProps('message') },
  },
}
</script>

<style lang="css" scoped>
.modal-title {
  font-family: 'Kite One';
}

.modal-body {
  padding-top: 30px;
  padding-bottom: 35px;
  font-family: 'Andika New Basic';
}
</style>
import utils from '@/utils.js'

export const namespaced = true

export const state = {
  notification: {}
}

export const mutations = {
  SET_NOTIFICATION (state, notification){
    state.notification = notification;
  },
}

export const actions = {
  showNotification ({commit}, notification){
    commit('SET_NOTIFICATION', notification);
    utils.showNotificationModal();
  }
}
      


export const namespaced = true

export const state = {
    isAuthenticated: false,
    isAuthChecked: false,
}

export const mutations = {
  UPDATE_ISAUTHENTICATED(state, status) {
    state.isAuthenticated = status;
  },

  UPDATE_ISAUTHCHECKED(state, status) {
    state.isAuthChecked = status;
  },
}


export const actions = {
  updateIsAuthenticated({ commit }, status) {
    // checking the type of the status is boolean
    status = typeof status == 'boolean' ? status : false 
    commit('UPDATE_ISAUTHENTICATED', status)
  },

  updateIsAuthChecked({ commit }, status) {
    // checking the type of the status is boolean
    status = typeof status == 'boolean' ? status : false 
    commit('UPDATE_ISAUTHCHECKED', status)
  },
}

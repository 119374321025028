import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'; // Import your Vuex store

import AuthServices from '@/services/AuthServices.js'



//  Changing the title of the website
document.title = 'BOQ.ai '



//  defining the route
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    props: true,
    meta: {
      requiresAuth: false, // custom property for authentication
    }
  },
  {
    path: '/authentication',
    name: 'Auth',
    component: () => import('../views/AuthView.vue'),
    props: true,
    meta: {
      requiresAuth: false, // custom property for authentication
    },
    beforeEnter: (to, from, next) => {
      // checking the user is authenticated or not
      let isUserAuthenticated = store.state.AuthStore.isAuthenticated;

      // if user is already authenticated, prevent navigation
      isUserAuthenticated ? next({name: 'Home'}) : next();
    },
  },
  {
    path: '/transform',
    name: 'Transform',
    component: () => import('../views/TransformView.vue'),
    props: true,
    meta: {
      requiresAuth: true, // custom property for authentication
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/404.vue'),
    props: true,
    meta: {
      requiresAuth: false, // custom property for authentication
    }
  },
  {
    path: '/about-us',
    name: 'About-Us',
    component: () => import('../views/AboutusView.vue'),
    props: true,
    meta: {
      requiresAuth: false, // custom property for authentication
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' }
  }
]


//  Creating route
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// navigation guard
router.beforeEach(async (to, from, next) => {
  // checking the system is already checked for the authenticaiton or not
  let isAuthChecked = store.state.AuthStore.isAuthChecked;
  if(!isAuthChecked) await AuthServices.checkUserIsAuthenticated();

  if (to.meta.requiresAuth) {
    // checking the user is authenticated or not
    let isUserAuthenticated = store.state.AuthStore.isAuthenticated;
    isUserAuthenticated ? next() : next({name: 'Auth'});
    
  } else {
    // no guard is required here
    next();
  }  
})


// Exporting the route
export default router

<template>
  <!-- Global NavigationBar -->
  <Base-Navbar />

  <!-- Global Notification Modal -->
  <Base-Notification-Modal />


  <!-- Render the Views -->
  <div id="wrapper-app-sh">

    <router-view v-slot="{ Component, route }">
      <transition name="fadeSH">
        <div :key="route.fullPath">
          <component :is="Component" />
        </div>
      </transition>
    </router-view>

  </div>

  <!-- Global Footer -->
  <Base-Footer />
</template>


<script lang="js">

import utils from '@/utils.js'
import NProgress from 'nprogress'
import { mapState, mapActions } from 'vuex'
import EventService from '@/services/EventService'
import AuthServices from '@/services/AuthServices.js'
import FetchUtils from '@/services/FetchUtils'
import store from './store'


export default {
  data() {
    return {}
  },

  methods: {
    ...mapActions(['addBaseNavbarHeight', 'addBaseFooterHeight']),
    ...mapActions('HistoryStore', ['updateHistory']),

    async checkUserIsAuthenticated(){
      await AuthServices.checkUserIsAuthenticated();
    },

    add_fixed_content_height() { 
      //  defining the measurement unit
      var unit = 'px'

      // selecting item
      var baseNavbar = document.querySelector('#Base-Navbar nav')
      var content = document.querySelector('#wrapper-app-sh')
      var baseFooter = document.querySelector('#Base-Footer')

      // accessing the height of items
      var baseNavbar_height = (baseNavbar != null) ? (baseNavbar.scrollHeight + unit) : `0${unit}` ;
      var baseFooter_height = (baseFooter != null) ? (baseFooter.scrollHeight + unit) : `0${unit}` ;

      // adding the height to store
      this.addBaseNavbarHeight(baseNavbar_height)
      this.addBaseFooterHeight(baseFooter_height)

      // adding style to content
      content.style.marginTop = baseNavbar_height;  // add margin-top same as nav height
      content.style.minHeight = `calc( 100vh - ( ${baseNavbar_height} + ${baseFooter_height}) )`;  // this will keep the footer at the bottom of the page
    },
  },

  computed: {},

  created() {
    // created life cycle hook gets called before child mounted hook gets called

    // checking user's authentication state
    this.checkUserIsAuthenticated()  
  },

  mounted() {
    this.add_fixed_content_height();

    this.updateHistory(FetchUtils.fetchHistoryLocal());
  },

  components: {},
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fadeSH-enter-active,
.fadeSH-leave-active {
  transition: all .5s ease-in;
}

.fadeSH-enter,
.fadeSH-leave-to {
  opacity: 0;
}
</style>


export const namespaced = true

export const state = {
  history: [],
}

export const mutations = {
  UPDATE_HISTORY(state, history) {
    // updating the whole state with new-data
    state.history = history;
  },
}


export const actions = {
  updateHistory({ commit }, history) {
    commit('UPDATE_HISTORY', history)
  },
}

import EventService from '@/services/EventService.js';

export const namespaced = true

export const state = {
  input_data: {},
}

export const mutations = {
  UPDATE_INPUT_DATA(state, input_data) {
    // updating the whole state with new-data
    state.input_data = input_data;
  }
}


export const actions = {
  updateInputData({ commit }, input_data) {
    commit('UPDATE_INPUT_DATA', input_data)
  }
}

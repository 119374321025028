// =================================================================================|
//                                                                                  |
//    Fetch Utility module : holds all the utility functions to fetch via apis      |
//                                                                                  |
// =================================================================================|


import store from '@/store'
import EventService from '@/services/EventService'


// ---------------------------------------------------------------
//    this function vaslidates incoming response from openai     |
// ---------------------------------------------------------------
function validate_response(response){
  let sections = ['title', 'project details', 'cost analysis', 'conclusion']

  if(response instanceof Object){
    let response_keys = Object.keys(response)

    // checking the response field is exact string, not any other data type
    response_keys.forEach(key => {
      if(typeof response[key] !== 'string') return false
    })

    response_keys = response_keys.map(key => key.toLowerCase())

    // checking the response contains the required section
    sections.forEach(section => {
      if(!response_keys.includes(section)) return false
    })
    
    return true 
  }

  return false
}



// ---------------------------------------------------------------
//    this function senitizes incoming response from openai      |
// ---------------------------------------------------------------
function senitize_response(response){
  let response_keys = Object.keys(response)

  response_keys.forEach(key => {
    let section = response[key];

    if(typeof section == 'string') {
      // sometimes '\r' exists as "carriage return" control character
      // Check if the string contains '\r'
      if (section.includes('\r')) {
        // Remove '\r' from the string
        response[key] = section.replace(/\r/g, '');
      }
    }
  })

  return response
}



// -------------------------------------------------------|
//    exporting the utils                                 |
// -------------------------------------------------------|
export default {
  validate_response,
  senitize_response,
}
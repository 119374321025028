<template>
  <div class="wrapper-sh" id='Base-Footer'>
    <div class="container container-sh">
      <div class="row row-sh justify-content-center">
        <div class="col align-self-center">
          
          <!-- contact-sh -->
          <!-- <div class="contact-sh"> 

            <div class="row justify-content-center">
              <div class="col-6 align-self-start">
                <div class="contact-title-sh"> Contact  </div>
              </div>

              <div class="col-6 align-self-start">
                <div class="contact-link-sh"> 
                  <a class="dropdown-item" href="mailto:hello@shohrab.com" target="_blank">
                    <i class="fa-solid fa-envelope fa-fw"></i>
                    Email: hello@shohrab.com
                  </a>

                  <a class="dropdown-item" href="https://www.linkedin.com/in/shohrab-hossain-003/" target="_blank">
                    <i class="fa-brands fa-linkedin fa-fw"></i>
                    /shohrab-hossain-003
                  </a>

                  <a class="dropdown-item" href="https://github.com/Shohrab-Hossain" target="_blank">
                    <i class="fa-brands fa-github fa-fw"></i>
                    /Shohrab-Hossain
                  </a>
                </div>
              </div>
            </div>

          </div> -->
          <!-- ^ contact-sh -->

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "BaseFooter",

  props: {},

  data() {
    return {}
  },

  methods: {},

  computed: {}
}
</script>

<style lang="scss" scoped>
.wrapper-sh {
  height: 150px;
  // background-color: rgb(1, 1, 48);
  background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}

.container-sh {
  height: 100%;

  .row-sh {
    height: 100%;
  }
}

.contact-sh {
  .contact-title-sh {
    color: white;
    font-size: 1.4rem;
    font-family: "Space Grotesk";
    text-align: right;
  }

  .contact-link-sh {
    color: white;
    font-size: 1.1rem;
    font-family: "Poppins";
    text-align: left;
  }
}
</style>
// -----------------------------------------------------|
//    Import                                            |
// -----------------------------------------------------|

//  initializing the app
import { createApp } from 'vue'
import App from './App.vue'

//  accessign store and router
import './registerServiceWorker'
import router from './router'
import store from './store'

// AWS Amplify
import {Amplify} from 'aws-amplify'
import awsExports from '@/aws-exports'

// accessing configuration of aws amplify
Amplify.configure(awsExports)


//  vue for validation
import Vuelidate from 'vuelidate';

// imorting css file for spinning progress
import 'nprogress/nprogress.css';

// for text manipulation
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'




// -----------------------------------------------------|
//    App Creation                                      |
// -----------------------------------------------------|

// creating the app
const app = createApp(App).use(store).use(router).use(Vuelidate)




// -----------------------------------------------------|
//    Components Global Registration                    |
// -----------------------------------------------------|

// Automatic Global Registration of Base Components
const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )


  // Register component globally
  app.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})




// -----------------------------------------------------|
//    App Rendering                                     |
// -----------------------------------------------------|

// rendering the app
app.mount('#app')

<template>
  <div id='Base-Navbar'>
    <!-- nav bar -->
    <nav class="navbar navbar-expand-lg fixed-top bg-body-tertiary" data-bs-theme="light">
      <div class="container">

        <router-link :to="{ name: 'Home' }" class="navbar-brand navbar-brand-sh">
          <img id="navbar-brand-logo-sh" :src=brandLogo alt="Bootstrap">
          <span id="navbar-brand-name-sh"> BOQ.ai </span>
        </router-link>


        <button class="navbar-toggler navbar-toggler-sh" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <div class="navbar-nav navbar-nav-sh">

            <router-link :to="{ name: 'Home' }" class="nav-item nav-item-sh">
              <span class="nav-link nav-link-sh" aria-current="page">
                HOME
              </span>
            </router-link>

            <router-link :to="{ name: 'Transform' }" v-if="isSignedIn" class="nav-item nav-item-sh">
              <span class="nav-link nav-link-sh" aria-current="page">
                Transform
              </span>
            </router-link>

            <span class="nav-item nav-item-sh" style="cursor: pointer;" @click="feature_comingSoon_notification()">
              <span class="nav-link nav-link-sh" aria-current="page">
                Payment
              </span>
            </span>

            <!-- <router-link :to="{ name: 'About-Us' }" class="nav-item nav-item-sh">
              <span class="nav-link nav-link-sh" aria-current="page">
                About us
              </span>
            </router-link> -->

            <div @click="signout" v-if="isSignedIn" class="nav-item nav-item-sh" style="cursor: pointer;">
              <span class="nav-link nav-link-sh">
                Sign Out
              </span>
            </div>

            <span class="nav-item nav-item-sh search-nav-sh" style="cursor: pointer;">
              <span class="nav-link nav-link-sh" aria-current="page">
                <!-- display only in mobile view -->
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1" @click="feature_comingSoon_notification()">
                    <i class="fa-solid fa-magnifying-glass fa-fw"></i>
                  </span>
                  <input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
                </div>
              </span>
            </span>

          </div>
        </div>

        <span class="search-sh" style="cursor: pointer;">
          <!-- display only in desktop view -->
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1" @click="feature_comingSoon_notification()">
              <i class="fa-solid fa-magnifying-glass fa-fw"></i>
            </span>
            <input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1">
          </div>
        </span>
      </div>
    </nav>
    <!-- ^ nav bar -->

  </div>
</template>

<script>
import { mapState } from 'vuex'
import AuthServices from '@/services/AuthServices.js'

export default {
  name: "BaseNavbar",

  props: {},

  data() {
    return {
      brandLogo: require('@/assets/logos/Brand-Logo.png'),
    }
  },

  methods: {
    signout(){
      AuthServices.handleSignOut();
    },

    feature_comingSoon_notification(){
      var errorMessage = {
        catergory: 'notification',
        title: 'Coming Soon',
        message: 'This feature will be available soon.'
      }
      this.$store.dispatch('notificationStore/showNotification', errorMessage);
    },
  },

  computed: {
    ...mapState('AuthStore', ['isAuthenticated']),

    isSignedIn(){
      return this.isAuthenticated;
    },
  }
}
</script>

<style scoped lang="scss">

/* ---------- navbar ---------- */
#Base-Navbar nav {
  background: rgb(194,233,200);
  background: -moz-radial-gradient(circle, rgba(194,233,200,1) 11%, rgba(168,174,226,1) 91%);
  background: -webkit-radial-gradient(circle, rgba(194,233,200,1) 11%, rgba(168,174,226,1) 91%);
  background: radial-gradient(circle, rgba(194,233,200,1) 11%, rgba(168,174,226,1) 91%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c2e9c8",endColorstr="#a8aee2",GradientType=1);
  opacity: 0.95;
  box-shadow: 1px 1px 10px 1px #dddcdc;

  #navbarNavDropdown {
    justify-content: center;
  }

  .container {
    .show {
      /* this add style to navbar, while navbar is collapsed. */
      margin-bottom: 10px;
    }

    .dropdown .show {
      /* this add style to dropdown, while navbar is collapsed. */
      margin-bottom: 0px;
    }
  }
}


.navbar-brand-sh {
  // color: #002233;
  // text-shadow: 3px 3px 3px rgba(184, 92, 255, 0.3882352941);
  color: #003303;
  text-shadow: 2px 2px 10px rgba(10, 46, 65, 0.3882352941);
  text-decoration: none;
  margin-left: 15px;

  #navbar-brand-logo-sh {
    width: 28px;
    margin-top: -8px;
  }

  #navbar-brand-name-sh {
    font-family: 'Pacifico';
    font-size: 1.8rem;
    font-weight: bold;
    // text-shadow: 3px 3px 3px #b85cff63;
  }
}


#Base-Navbar .navbar-toggler-sh {
  margin-right: 15px;
}


#Base-Navbar .navbar-nav-sh {
  align-items: center;

  .search-nav-sh{
    // display only in mobile view
    display: none;
  }
}

#Base-Navbar .search-sh{
  // display only in desktop view
  font-family: 'Pacifico';
}


#Base-Navbar .navbar-nav {
  .nav-link-sh {
    padding: 6px 15px;
    color: #5d5d5da6;
  }

  .nav-item-sh {
    text-decoration: none;
    letter-spacing: 1px;
    text-align: left;
    font-family: 'Protest Guerrilla';
    font-weight: normal;
  }
}


.router-link-active :nth-child(1) {
  /* style for avtive link in nav */
  // color: #fff !important;
  color: #555555 !important;
  font-weight: bolder;
}


// from extra-small screen to large-screen
@media only screen and (max-width: 960px) {
  
  #Base-Navbar {
    .navbar .navbar-nav-sh {
      align-items: start;

      .search-nav-sh{
        display: unset;
      }
    }

    .search-sh {
      // display only in desktop view
      display: none;
    }
  }
}

/* ---------- ^ navbar ---------- */

</style>
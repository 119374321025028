import { createStore } from 'vuex'
import utils from '@/utils.js'


//  importing the module
import * as HomeViewStore from '@/store/modules/HomeViewStore.js'
import * as AuthStore from '@/store/modules/AuthStore.js'
import * as HistoryStore from '@/store/modules/HistoryStore.js'
import * as AboutusViewStore from '@/store/modules/AboutusViewStore.js'
import * as notificationStore from '@/store/modules/notificationStore.js'


export default createStore({
  state: {
    Base_Navbar_Height: '',
    Base_Footer_Height: '',

    baseURL: utils.baseURL,

    mlServerURL: utils.mlServerURL,
  },

  getters: {
  },

  mutations: {
    UPDATE_BASE_NAVBAR_HEIGHT(state, height) {
      state.Base_Navbar_Height = height;
    },

    UPDATE_BASE_FOOTER_HEIGHT(state, height) {
      state.Base_Footer_Height = height;
    },
  },
  
  
  actions: {
    addBaseNavbarHeight({ commit }, height) {
      commit('UPDATE_BASE_NAVBAR_HEIGHT', height)
    },

    addBaseFooterHeight({ commit }, height) {
      commit('UPDATE_BASE_FOOTER_HEIGHT', height)
    },
  },

  modules: {
    HomeViewStore,
    AuthStore,
    AboutusViewStore,
    HistoryStore,
    notificationStore
  }
})

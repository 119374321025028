// # ================================================================ #
// #    Author: Shohrab Hossain                                       #
// # ================================================================ #
// #                                                                  #
// #    Auth Service Module:                                          #
// #        this module holds the functions to handle authentication  #
// #        AWS Amplify is used to handle the authentication          #
// #                                                                  #
// # ================================================================ #


import store from '@/store'
import router from '@/router'


import { getCurrentUser } from 'aws-amplify/auth';
import { signUp, signIn, signOut  } from 'aws-amplify/auth';
import { confirmSignUp, resendSignUpCode, resetPassword, confirmResetPassword   } from 'aws-amplify/auth';





// # ---------------------------------------------------------------- #
// #     Sign Up Handler                                              #
// # ---------------------------------------------------------------- #

async function handleSignUp({email, password, given_name, family_name}) {
  try {
    // defining variables
    let res = {};
    let username = email;

    // calling AWS-Amplify's signup api
    const { isSignUpComplete, userId, nextStep } = await signUp({
      username,
      password,
      options: {
        userAttributes: {
          email,
          given_name,
          family_name
        },
        // optional
        autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
      }
    });

    // handling the next step
    switch (nextStep.signUpStep) {
      case 'CONFIRM_SIGN_UP':
        // confoirmation required
        res.isConfirmationRequired = true
        res.username = username
        break;
      case 'DONE':
        // signup completed
        // updating store
        updateAuthStore({isAuthenticated: true})

        // moving to homepage
        router.push({name: 'Home'})
        break;
    }

    // returning a custom to handle the next step
    return res

  } catch (error) {
    // flashihng the error
    flashErrorNotification({title:'Sign Up Failed', message:error.message});

    return {}
  }
}




// # ---------------------------------------------------------------- #
// #     Sign In Handler                                              #
// # ---------------------------------------------------------------- #

async function handleSignIn({ username, password }) {
  try {
    // defining variables
    let res = {};

    // calling AWS-Amplify's signin api
    const { isSignedIn, nextStep } = await signIn({ username, password });

    // handling the next step
    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_UP':
        // confoirmation required
        res.isConfirmationRequired = true
        res.username = username
        break;
        
      case 'RESET_PASSWORD':
        // password needs to be reset
        res.isPasswordResetRequired = true
        res.username = username
        break;

      case 'DONE':
        // signin completed
        // updating store
        updateAuthStore({isAuthenticated: true})

        // moving to home
        router.push({name: 'Home'})
        break;
    }

    // returning a custom to handle the next step
    return res;

  } catch (error) {
    // flashihng the error
    flashErrorNotification({title:'Sign In Failed', message:error.message})

    return {}

  }
}




// # ---------------------------------------------------------------- #
// #     Sign Out Handler                                              #
// # ---------------------------------------------------------------- #

async function handleSignOut() {
  try {
    // calling AWS-Amplify's signout api
    await signOut();

    // updating store
    updateAuthStore({isAuthenticated: false})

    // mpoving to homaepage
    router.push({name: 'Home'})

  } catch (error) {
    // flashihng the error
    flashErrorNotification({title:'Sign Out Failed', message:error.message})

  }
}




// # ---------------------------------------------------------------- #
// #     Confirtamtion Challange Handler                              #
// # ---------------------------------------------------------------- #

async function handleAuthConfirmation({ username, confirmationCode }) {
  try {
    // calling AWS-Amplify's confirm-signup api
    const { isSignUpComplete, nextStep } = await confirmSignUp({
      username,
      confirmationCode
    });

    // authenication completed
    if(isSignUpComplete){
      // updating store
      updateAuthStore({isAuthenticated: true})
    }

    // moving to homepage
    router.push({name: 'Home'})

  } catch (error) {
    // flashihng the error
    flashErrorNotification({title:'Confirmation Failed', message:error.message})

    return {}

  }
}

async function getNewVerificationCode(username){
  try {
    // calling AWS-Amplify's resend-signup-code api
    await resendSignUpCode({
      username
    })
    
  } catch (error) {
    // flashihng the error
    flashErrorNotification({title:'Failed To Send New Code', message:error.message})

  }
}




// # ---------------------------------------------------------------- #
// #     Reset Password Handler                                       #
// # ---------------------------------------------------------------- #

async function handleResetPassword({ username }) {
  try {
    // defining variables
    let res = {};

    const { nextStep } = await resetPassword({ username });
    
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        // confoirmation required
        res.isConfirmationRequired = true
        res.username = username

        break;
      case 'DONE':
        // password reset completed
        // updating store
        updateAuthStore({isAuthenticated: true})

        // moving to home
        router.push({name: 'Home'})
        break;
    }

    // returning a custom to handle the next step
    return res;

  } catch (error) {
    // flashihng the error
    flashErrorNotification({title:'Sign In Failed', message:error.message})

    return {}
  }
}




// # ---------------------------------------------------------------- #
// #     Reset Password Challange Handler                             #
// # ---------------------------------------------------------------- #

async function handleConfirmResetPassword({username, confirmationCode, newPassword}) {
  try {
    await confirmResetPassword({ username, confirmationCode, newPassword });

    // updating store
    updateAuthStore({isAuthenticated: true})

    // moving to home
    router.push({name: 'Home'})

  } catch (error) {
    // flashihng the error
    flashErrorNotification({title:'Confirmation Failed', message:error.message})

    return {}
  }
}




// # ---------------------------------------------------------------- #
// #     User Authentication Checker                                   #
// # ---------------------------------------------------------------- #

async function checkUserIsAuthenticated(){
  // dont make this function async, we want to wait till the aws api calling resolves
  try{
    // calling AWS-Amplify's get-current-user api
    const { username, userId, signInDetails } = await getCurrentUser();
    updateAuthStore({isAuthenticated: true, isAuthChecked: true})

  } catch (error) {
    // user is not authenticated
    updateAuthStore({isAuthenticated: false, isAuthChecked: true});

  }
}




// # ---------------------------------------------------------------- #
// #     Error Handler                                                #
// # ---------------------------------------------------------------- #

function flashErrorNotification({title, message}){
  // defining the error message
  let errorMessage = {
    catagory: 'error',
    title,
    message
  };

  // adding the error to notification store, from where the modal will be flashed
  store.dispatch('notificationStore/showNotification', errorMessage);
}




// # ---------------------------------------------------------------- #
// #     Update Store                                                 #
// # ---------------------------------------------------------------- #

function updateAuthStore({isAuthenticated, isAuthChecked}){
  // updating isAuthenticated
  if(typeof isAuthenticated == 'boolean') store.dispatch('AuthStore/updateIsAuthenticated', isAuthenticated);

  // updating isAuthChecked
  if(typeof isAuthChecked == 'boolean') store.dispatch('AuthStore/updateIsAuthChecked', isAuthChecked);
}



// -------------------------------------------------------------|
//                                                              |
//    Exporting the module                                      |
//                                                              |
// -------------------------------------------------------------|

export default {
  checkUserIsAuthenticated,

  handleSignUp,
  handleSignIn,
  handleSignOut,

  handleAuthConfirmation,
  getNewVerificationCode,

  handleResetPassword,
  handleConfirmResetPassword,
}
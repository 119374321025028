// =================================================================================|
//                                                                                  |
//    Fetch Utility module : holds all the utility functions to fetch via apis      |
//                                                                                  |
// =================================================================================|


import store from '@/store'
import EventService from '@/services/EventService'


// -------------------------------------------------------
//    this function deals with history storing           |
// -------------------------------------------------------
const local_store_key = 'bim_gai_costplan_history'

function prepareHistory(where_to_push){
  let history = fetchHistoryLocal();

  let HomeViewStoreState = store.state.HomeViewStore;

  let costplan = {
    ...HomeViewStoreState,
    timestamp: new Date().getTime(),
  };

  if(history.length !== where_to_push){
    // history already storing this version of the costplan, so just updating
    history[where_to_push] = costplan;
  } else {
    // this version of the costplan does not exists, so pushing 
    history.push(costplan);
  }

  store.dispatch('HistoryStore/updateHistory', history);

  return history
}




function storeHistoryLocally(where_to_push) {
  
  let history = prepareHistory(where_to_push)

  // stringifing history
  let history_string = JSON.stringify(history);

  // storing locally
  localStorage.setItem(local_store_key, history_string);
}




function fetchHistoryLocal(){
  // fetching history from local
  let history_string = localStorage.getItem(local_store_key);

  if(history_string) {
    let history = JSON.parse(history_string);
    if(Array.isArray(history)){
      // history successfully fetched from local
      return history
    }
  }
  
  // history not found: returning an empty array
  return []
}




// -------------------------------------------------------|
//    exporting the utils                                 |
// -------------------------------------------------------|
export default {
  storeHistoryLocally,
  fetchHistoryLocal,
}